import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 300.000000 300.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">
				

				<path d="M171 2892 c-19 -9 -44 -30 -55 -45 -21 -28 -21 -29 -21 -1347 0
-1318 0 -1319 21 -1347 11 -15 36 -36 55 -45 32 -17 116 -18 1329 -18 1213 0
1297 1 1329 18 19 9 44 30 55 45 21 28 21 29 21 1347 0 1318 0 1319 -21 1347
-11 15 -36 36 -55 45 -32 17 -116 18 -1329 18 -1213 0 -1297 -1 -1329 -18z
m2649 -39 c16 -9 33 -27 39 -40 8 -17 11 -409 11 -1321 0 -1214 -2 -1298 -18
-1318 -9 -12 -27 -27 -39 -33 -28 -14 -2589 -16 -2627 -1 -59 22 -56 -51 -56
1360 0 1189 1 1294 17 1320 9 16 26 33 37 39 14 6 466 10 1314 10 1190 1 1296
0 1322 -16z"/>
<path d="M679 2592 l-34 -30 -3 -524 c-3 -584 -1 -602 64 -738 185 -386 664
-521 1022 -288 79 52 189 168 229 244 110 207 119 438 24 639 -71 150 -185
268 -325 335 -117 56 -204 75 -319 71 -54 -2 -97 -45 -97 -98 0 -67 52 -113
127 -113 152 -1 338 -121 411 -265 51 -104 67 -243 38 -350 -37 -141 -148
-269 -280 -324 -283 -118 -592 36 -670 334 -13 49 -15 131 -15 467 0 224 4
410 8 412 11 7 55 -41 74 -80 14 -26 16 -84 18 -366 0 -184 6 -359 11 -388 29
-158 155 -280 321 -311 163 -30 334 61 409 219 139 290 -132 616 -445 537 -38
-9 -70 -15 -72 -13 -2 2 -6 76 -8 163 -3 154 -4 163 -35 236 -62 145 -200 245
-353 257 -63 4 -68 3 -100 -26z m145 -7 c126 -27 216 -97 274 -213 l37 -76 3
-197 3 -197 52 26 c46 24 64 27 147 27 82 0 103 -4 150 -26 78 -37 131 -87
171 -161 33 -60 34 -67 34 -168 0 -100 -1 -108 -34 -168 -41 -75 -94 -125
-170 -161 -48 -22 -69 -26 -151 -26 -76 0 -104 5 -143 22 -70 33 -149 113
-183 186 l-29 62 -5 375 -5 375 -25 45 c-23 41 -99 110 -121 110 -7 0 -9 -157
-7 -452 4 -401 6 -459 22 -508 56 -171 173 -294 337 -351 91 -32 228 -32 322
0 305 103 445 451 295 733 -83 156 -238 258 -420 275 -102 9 -138 48 -107 116
22 49 79 58 207 31 312 -64 542 -345 542 -658 -1 -303 -189 -562 -474 -652
-312 -98 -653 42 -803 331 -78 150 -76 136 -80 720 -4 559 -4 556 47 582 31
16 30 16 114 -2z"/>
<path d="M1249 1772 c-43 -22 -59 -38 -81 -81 -35 -67 -35 -112 -2 -180 94
-187 374 -121 374 89 0 82 -47 153 -122 185 -56 23 -108 19 -169 -13z m189
-29 c50 -37 72 -80 72 -143 0 -63 -22 -106 -73 -144 -41 -31 -150 -30 -193 1
-43 31 -63 65 -71 118 -9 69 18 130 76 167 40 26 53 29 104 26 37 -3 67 -11
85 -25z"/>
<path d="M1294 2609 c-29 -15 -54 -61 -54 -101 0 -25 8 -42 30 -63 26 -25 41
-30 137 -41 161 -19 267 -55 375 -128 218 -146 347 -366 365 -622 5 -77 9 -91
35 -120 36 -41 76 -51 117 -29 57 29 65 52 58 178 -13 236 -109 448 -287 628
-141 143 -284 227 -479 280 -87 24 -265 35 -297 18z m251 -35 c400 -76 727
-426 784 -838 18 -125 15 -163 -14 -191 -31 -32 -76 -33 -108 -2 -21 19 -26
36 -35 128 -32 305 -199 548 -467 679 -100 48 -183 70 -304 80 -86 6 -97 9
-118 34 -13 15 -23 37 -23 49 0 29 26 66 54 77 26 11 125 4 231 -16z"/>
<path d="M673 849 c-18 -5 -35 -14 -38 -19 -4 -6 -15 -4 -29 5 -54 36 -76 -6
-76 -143 0 -94 1 -98 31 -129 27 -30 29 -38 5 -34 -5 1 -6 -5 -2 -14 3 -9 6
-21 6 -26 0 -19 18 1 24 27 4 16 3 30 -4 34 -6 4 0 12 14 20 21 11 28 11 41 0
22 -19 19 -43 -5 -37 -11 3 -20 4 -20 3 0 -2 5 -18 12 -37 l11 -33 12 34 c16
47 39 50 54 8 11 -29 13 -31 22 -15 6 10 8 25 5 33 -4 11 -1 14 11 12 9 -2 21
-19 28 -38 l12 -34 11 33 c14 41 15 44 3 37 -14 -9 -41 4 -41 19 0 7 7 16 17
19 21 8 69 -40 79 -79 l7 -30 12 35 c15 44 31 49 40 11 7 -35 20 -40 28 -12 3
11 -4 26 -16 37 l-21 18 28 9 c37 12 96 -18 96 -50 0 -33 18 -73 32 -72 10 0
10 2 1 6 -23 9 -14 24 11 17 13 -3 27 -1 30 5 4 7 -1 11 -12 11 -11 0 -26 7
-33 16 -11 14 -10 19 8 34 12 10 19 20 16 24 -4 3 4 16 16 27 l23 22 26 -27
c15 -14 21 -26 14 -26 -7 0 -11 -4 -8 -9 3 -5 15 -7 25 -4 10 3 22 0 25 -6 5
-7 13 -7 26 2 29 18 54 -2 60 -48 6 -44 26 -59 25 -18 0 23 5 29 30 34 24 5
26 7 13 13 -9 4 -22 5 -27 1 -6 -3 -21 4 -33 16 -22 20 -22 21 -2 11 12 -6 24
-9 27 -5 3 3 18 3 32 -1 21 -5 25 -12 25 -45 0 -25 5 -41 15 -45 12 -4 15 6
15 57 0 34 -4 57 -8 51 -10 -15 -42 -14 -42 2 0 7 8 18 18 23 21 11 52 -8 52
-32 0 -10 6 -14 14 -11 48 19 77 -58 34 -90 l-23 -17 23 11 c12 6 33 8 45 4
18 -5 20 -4 10 7 -21 22 -16 86 9 109 23 21 53 26 63 10 9 -15 36 -12 57 7 18
16 21 16 53 -2 27 -14 32 -21 24 -31 -8 -9 -18 -10 -36 -3 -14 5 -28 8 -30 5
-3 -2 5 -7 16 -11 15 -4 23 -18 27 -45 5 -28 13 -41 33 -50 l26 -12 -24 26
c-17 18 -22 31 -17 50 3 15 11 26 16 26 6 0 10 6 10 14 0 8 5 18 12 22 7 5 9
2 5 -9 -3 -9 1 -18 9 -21 22 -8 22 -8 27 17 3 16 5 14 6 -9 2 -49 13 -94 22
-94 5 0 9 27 9 60 0 33 4 60 10 60 6 0 10 -26 10 -59 0 -44 4 -60 16 -65 24
-9 29 -7 16 6 -20 20 -14 63 13 86 14 12 25 26 25 32 0 14 33 13 48 -2 15 -15
-4 -39 -25 -31 -14 5 -14 3 1 -19 19 -29 13 -38 -26 -38 -18 0 -27 -4 -22 -11
3 -6 17 -8 30 -5 25 7 34 -8 11 -17 -9 -4 -9 -6 1 -6 7 -1 15 6 18 14 4 10 15
12 35 8 18 -3 29 -1 29 6 0 6 -8 11 -19 11 -10 0 -24 7 -31 15 -17 21 6 48 36
42 14 -2 25 2 28 9 3 8 17 14 31 14 22 0 25 -3 20 -21 -6 -19 -5 -20 14 -9 22
11 61 5 61 -10 0 -5 -12 -8 -27 -7 -24 2 -28 -2 -28 -23 0 -21 4 -25 28 -23
19 1 27 -2 24 -10 -3 -7 5 -12 19 -12 21 0 21 1 7 17 -34 38 11 93 57 68 25
-14 27 -65 3 -79 -20 -11 -4 -15 21 -5 24 9 21 79 -4 97 -23 16 -16 27 17 27
17 0 23 -5 21 -17 -2 -13 3 -17 19 -15 12 2 24 -2 27 -7 5 -7 15 -7 31 1 32
14 52 -2 58 -45 l4 -32 1 31 c1 20 -5 37 -18 49 l-20 18 25 27 c33 34 38 63
19 110 -14 35 -14 41 -1 61 17 24 13 44 -15 75 -26 29 -70 37 -118 20 -24 -8
-42 -10 -48 -4 -5 5 -36 10 -68 10 -78 1 -94 -18 -94 -112 0 -75 -16 -104 -45
-80 -11 9 -15 33 -15 85 0 58 -4 75 -19 89 -17 16 -23 16 -48 4 -24 -12 -34
-11 -76 4 -40 15 -55 17 -89 7 -23 -6 -54 -8 -69 -5 -19 3 -32 -1 -44 -14 -17
-19 -19 -19 -54 -2 -50 25 -132 21 -162 -7 l-23 -22 -8 21 c-10 28 -34 35 -60
18 -19 -13 -26 -12 -57 3 -49 23 -121 13 -137 -18 l-11 -23 -33 25 c-42 31
-52 31 -87 1 l-28 -24 -22 24 c-28 31 -58 30 -87 -1 -13 -14 -27 -25 -31 -25
-4 0 -16 11 -27 25 -20 26 -56 33 -75 14 -8 -8 -24 -6 -57 5 -26 9 -48 16 -49
15 -1 0 -16 -5 -34 -10z m-73 -146 c0 -116 -1 -123 -20 -123 -19 0 -20 7 -20
123 0 116 1 123 20 123 19 0 20 -7 20 -123z m160 107 c26 -26 26 -60 1 -60
-10 0 -21 9 -24 20 -7 27 -35 36 -53 18 -20 -19 -9 -44 26 -58 44 -18 70 -48
70 -80 0 -64 -79 -93 -127 -48 -29 27 -30 58 -3 58 11 0 20 -6 20 -14 0 -21
27 -38 50 -31 36 11 26 52 -19 75 -40 20 -71 54 -71 78 0 7 11 24 25 37 30 31
77 33 105 5z m702 -187 c16 -20 46 -16 58 8 7 12 10 56 8 105 -3 81 -2 84 20
84 21 0 22 -3 22 -94 0 -79 -3 -97 -21 -120 -27 -34 -88 -37 -119 -4 -17 19
-20 35 -20 119 0 85 2 98 18 101 15 3 17 -7 20 -91 1 -52 8 -101 14 -108z
m213 135 c19 -32 35 -63 35 -68 0 -5 5 -12 11 -16 8 -5 10 15 7 70 -3 71 -2
76 17 76 19 0 20 -5 17 -120 -2 -85 -7 -120 -15 -120 -7 0 -34 38 -60 83 l-48
82 7 -82 c6 -80 5 -83 -15 -83 -20 0 -21 5 -21 125 0 145 8 151 65 53z m752
50 c13 -12 23 -30 23 -40 0 -25 -36 -23 -43 2 -7 26 -33 36 -52 20 -25 -21
-17 -36 38 -71 62 -40 73 -76 36 -115 -42 -45 -129 -20 -129 36 0 27 36 27 43
0 7 -26 33 -36 52 -20 25 21 17 48 -17 66 -77 39 -96 79 -56 121 28 30 74 30
105 1z m-1548 -25 c6 -21 17 -65 24 -98 15 -66 15 -67 43 58 17 74 19 77 46
77 l28 0 0 -120 c0 -116 -1 -120 -21 -120 -21 0 -22 3 -16 93 7 103 -1 99 -28
-16 -17 -67 -22 -77 -39 -75 -17 2 -25 19 -43 93 l-22 90 0 -93 c-1 -80 -3
-93 -17 -90 -14 2 -16 21 -18 121 l-1 117 27 0 c23 0 29 -6 37 -37z m294 -68
c15 -57 30 -112 34 -121 4 -12 0 -15 -18 -12 -18 2 -25 11 -29 33 -4 25 -10
30 -38 33 -26 3 -32 0 -32 -17 0 -33 -11 -51 -32 -51 -14 0 -18 5 -14 18 2 9
17 63 32 120 24 90 29 102 49 102 19 0 24 -12 48 -105z m185 93 c15 -15 16
-84 2 -93 -6 -4 -4 -12 5 -21 18 -19 20 -64 2 -88 -9 -13 -29 -18 -75 -20
l-62 -3 0 118 0 119 58 0 c32 0 63 -5 70 -12z m576 -4 c23 -22 29 -56 24 -125
-6 -77 -20 -91 -95 -93 l-58 -1 -3 118 -3 117 60 0 c41 0 65 -5 75 -16z m98
-84 l-4 -100 46 0 c39 0 46 -3 46 -20 0 -18 -7 -20 -65 -20 l-65 0 0 120 c0
118 0 120 23 120 22 0 22 -2 19 -100z m248 85 c0 -11 -12 -15 -45 -15 -43 0
-45 -1 -45 -30 0 -27 3 -30 30 -30 23 0 30 -4 30 -20 0 -16 -7 -20 -30 -20
-28 0 -30 -3 -30 -35 0 -35 0 -35 45 -35 38 0 45 -3 45 -20 0 -18 -7 -20 -65
-20 l-65 0 0 120 0 120 65 0 c51 0 65 -3 65 -15z m9 -136 c-19 -12 -39 -11
-39 1 0 6 5 10 10 10 6 0 10 10 10 22 l0 22 16 -23 c14 -19 14 -25 3 -32z
m-1396 -114 c-3 -20 -33 -31 -43 -15 -5 8 -6 18 -3 23 7 11 48 4 46 -8z"/>
<path d="M1112 726 c-13 -45 -11 -49 15 -44 17 3 18 7 7 43 l-11 40 -11 -39z"/>
<path d="M1270 755 c0 -32 3 -35 29 -35 31 0 45 20 36 51 -4 13 -16 19 -36 19
-27 0 -29 -3 -29 -35z"/>
<path d="M1270 655 c0 -35 1 -36 33 -33 28 3 32 6 32 33 0 27 -4 30 -32 33
-32 3 -33 2 -33 -33z"/>
<path d="M1840 705 l0 -85 28 0 c41 0 52 17 52 78 0 69 -12 92 -50 92 l-30 0
0 -85z"/>
<path d="M1613 543 c-8 -3 -13 -24 -13 -54 0 -39 3 -47 15 -43 10 4 15 20 15
51 0 36 3 44 15 39 8 -3 15 -1 15 4 0 10 -26 12 -47 3z"/>
<path d="M1120 528 c0 -11 -7 -14 -25 -10 -14 2 -25 0 -25 -6 0 -6 10 -12 22
-14 26 -3 37 -36 16 -50 -10 -7 -8 -8 7 -6 15 2 21 12 23 42 2 21 -1 43 -7 49
-8 8 -11 6 -11 -5z"/>
<path d="M670 496 c-14 -38 -13 -41 14 -41 21 0 22 2 13 30 -14 41 -15 42 -27
11z"/>
<path d="M811 490 c-6 -18 -11 -33 -11 -34 0 -1 11 -1 25 -1 20 0 24 4 19 20
-14 45 -21 48 -33 15z"/>
<path d="M1007 513 c-8 -17 -6 -63 3 -63 6 0 10 16 10 35 0 34 -5 43 -13 28z"/>
<path d="M1167 513 c-4 -3 -7 -17 -7 -30 0 -16 6 -23 20 -23 16 0 20 7 20 30
0 28 -17 40 -33 23z"/>
<path d="M1226 505 c-11 -30 -8 -49 7 -36 10 8 17 7 26 -2 10 -10 12 -6 9 18
-3 33 -32 46 -42 20z"/>
<path d="M1434 506 c-9 -24 4 -48 23 -44 24 4 24 52 0 56 -9 2 -20 -4 -23 -12z"/>
<path d="M1657 513 c-4 -3 -7 -15 -7 -26 0 -16 3 -17 15 -7 8 7 16 10 16 6 1
-3 2 -10 3 -15 2 -20 16 -23 16 -3 0 42 -22 66 -43 45z"/>
<path d="M1754 505 c-8 -21 2 -45 19 -45 8 0 20 7 27 15 10 12 10 18 0 30 -7
8 -19 15 -27 15 -7 0 -16 -7 -19 -15z"/>
<path d="M2000 511 c0 -5 14 -11 30 -14 34 -7 38 -22 13 -49 -9 -10 -13 -18
-10 -18 3 0 16 10 27 22 14 15 23 19 28 12 4 -6 18 -16 32 -23 l25 -12 -22 17
c-13 9 -23 27 -23 41 0 21 -2 23 -15 13 -11 -10 -18 -9 -30 4 -17 17 -55 22
-55 7z"/>
<path d="M2217 513 c-13 -12 -7 -44 9 -50 23 -9 48 25 33 44 -12 14 -32 17
-42 6z"/>
<path d="M2327 513 c-4 -3 -7 -17 -7 -30 0 -16 6 -23 19 -23 25 0 34 14 26 40
-6 20 -25 26 -38 13z"/>
<path d="M2386 505 c-3 -9 -2 -24 4 -33 9 -16 9 -15 10 1 0 10 5 15 10 12 6
-4 8 -11 5 -16 -4 -5 -1 -9 5 -9 6 0 10 12 8 27 -3 31 -32 43 -42 18z"/>
<path d="M1540 489 c0 -21 18 -35 30 -23 18 19 14 37 -7 39 -16 1 -23 -4 -23
-16z"/>
<path d="M602 483 c-16 -29 -15 -30 8 -36 21 -5 24 4 11 38 -8 19 -8 19 -19
-2z"/>
<path d="M743 483 c-3 -10 -8 -21 -9 -24 -2 -4 7 -9 20 -11 22 -5 23 -3 14 24
-12 33 -16 35 -25 11z"/>
<path d="M882 472 c-9 -27 -8 -28 13 -24 17 3 21 9 18 23 -8 31 -20 31 -31 1z"/>
<path d="M1330 480 c-12 -8 -12 -10 2 -15 19 -7 38 1 38 16 0 12 -21 11 -40
-1z"/>
</g>
</svg>

		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
