// theme
import palette from '../theme/palette';

// ----------------------------------------------------------------------

export const colorPresets = [
  // DEFAULT
  {
    name: 'default',
    ...palette.light.primary,
  },
  // PURPLE
  {
    name: 'purple',
    lighter: '#EBD6FD',
    light: '#B985F4',
    main: '#7635dc',
    dark: '#431A9E',
    darker: '#200A69',
    contrastText: '#fff',
  },
  // CYAN
  {
    name: 'cyan',
    lighter: '#D1FFFC',
    light: '#76F2FF',
    main: '#1CCAFF',
    dark: '#0E77B7',
    darker: '#053D7A',
    contrastText: palette.light.grey[800],
  },
  // BLUE
  {
    name: 'blue',
    lighter: '#D1E9FC',
    light: '#76B0F1',
    main: '#2065D1',
    dark: '#103996',
    darker: '#061B64',
    contrastText: '#fff',
  },
  // ORANGE
  {
    name: 'orange',
    lighter: '#FEF4D4',
    light: '#FED680',
    main: '#fda92d',
    dark: '#B66816',
    darker: '#793908',
    contrastText: palette.light.grey[800],
  },
  // RED
  {
    name: 'red',
    lighter: '#FFE3D5',
    light: '#FFC1AC',
    main: '#FF3030',
    dark: '#B71833',
    darker: '#7A0930',
    contrastText: '#fff',
  },
  // GREEN
  {
    name: 'green',
    lighter: '#D5FFD5',
    light: '#A2FFA2',
    main: '#45FF45',
    dark: '#2DCC2D',
    darker: '#1A991A',
    contrastText: palette.light.grey[800],
  },
  // YELLOW
  {
    name: 'yellow',
    lighter: '#FFFFD5',
    light: '#FFFFA2',
    main: '#FFFF45',
    dark: '#CCCC2D',
    darker: '#99991A',
    contrastText: palette.light.grey[800],
  },
  // PINK
  {
    name: 'pink',
    lighter: '#FFD5E6',
    light: '#FFA2C3',
    main: '#FF459D',
    dark: '#CC2D5E',
    darker: '#991A35',
    contrastText: '#fff',
  },
  // TEAL
  {
    name: 'teal',
    lighter: '#D5FFFD',
    light: '#A2FFFF',
    main: '#45FFFF',
    dark: '#2DCCCC',
    darker: '#1A9999',
    contrastText: palette.light.grey[800],
  },
  // LAVENDER
  {
    name: 'lavender',
    lighter: '#E6D5FF',
    light: '#C3A2FF',
    main: '#4b006e',
    dark: '#5E2DCC',
    darker: '#351A99',
    contrastText: '#fff',
  },
  // BROWN
  {
    name: 'brown',
    lighter: '#FFD5D5',
    light: '#FFA2A2',
    main: '#FF4545',
    dark: '#CC2D2D',
    darker: '#991A1A',
    contrastText: palette.light.grey[800],
  },
];

export const defaultPreset = colorPresets[0];
export const purplePreset = colorPresets[1];
export const cyanPreset = colorPresets[2];
export const bluePreset = colorPresets[3];
export const orangePreset = colorPresets[4];
export const redPreset = colorPresets[5];
export const greenPreset = colorPresets[6];
export const yellowPreset = colorPresets[7];
export const pinkPreset = colorPresets[8];
export const tealPreset = colorPresets[9];
export const lavenderprest = colorPresets[10];
export const brownprest = colorPresets[11];


export default function getColorPresets(presetsKey) {
  return {
    purple: purplePreset,
    cyan: cyanPreset,
    blue: bluePreset,
    orange: orangePreset,
    red: redPreset,
    default: defaultPreset,
    green: greenPreset,
    yellow: yellowPreset,
    pink: pinkPreset,
    teal: tealPreset,
    lavender: lavenderprest,
    brown: brownprest
  }[presetsKey];
}
